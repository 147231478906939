<template>
    <div>
        <Navbar :user="user" @clicklogin="clicklogin()" @reload="reload()"
            :deferredPrompt="!pwa && deferredPrompt?deferredPrompt:null">
        </Navbar>
        <div class="relative">
            <div :class="$route.path == '/watch' ? '' : 'max-w-7xl mx-auto px-4 sm:px-6 pb-6 container'">
                <router-view :user="user" :favorites="favorites" class="view">
                </router-view>
            </div>
            <Transition name="fade">
                <div class="loading" v-if="loading">
                    <div class="flex alitems-center items-center justify-center h-full w-full">
                        <div class="loading-bar" style="animation-delay: -1s;"></div>
                        <div class="loading-bar" style="animation-delay: -0.75s;"></div>
                        <div class="loading-bar" style="animation-delay: -0.5s;"></div>
                        <div class="loading-bar" style="animation-delay: -0.25s;"></div>
                        <div class="loading-bar" style="animation-delay: 0s;"></div>
                    </div>
                </div>
            </Transition>
            <Transition name="fade">
                <div class="loading" v-if="error">
                    <div class="flex alitems-center items-center justify-center h-full w-full">
                        <div class="flex-1">
                            <div class="error-image">
                                <img v-show="errorIndex == 0" src="@/assets/error/yuri.jpg" alt="">
                                <img v-show="errorIndex == 1" src="@/assets/error/02.png" alt="">
                                <img v-show="errorIndex == 2" src="@/assets/error/server.png" alt="">
                                <img v-show="errorIndex == 3" src="@/assets/error/CYhludL.jpg" alt="">
                            </div>
                            <h1 class="text-3xl text-center mt-5">發生錯誤</h1>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
        <div class="bg-slate-900 text-white text-center py-5">
            <span>dannyball710.net © 2022</span>
            <a href="./PrivacyPolicy.html" class="nav-link">《隱私權條款》</a>
            <a href="./Service.html" class="nav-link">《服務條款》</a>
        </div>
    </div>
</template>

<script>
    import firebase from "firebase/app";
    import "firebase/auth";
    import Navbar from '@/components/Navbar'

    const firebaseConfig = {
        apiKey: "AIzaSyAj72djGfNQZo7LVMNar7r7V_mF0af5itM",
        authDomain: "anime-app-311013.firebaseapp.com",
        projectId: "anime-app-311013",
        storageBucket: "anime-app-311013.appspot.com",
        messagingSenderId: "621625734824",
        appId: "1:621625734824:web:f8893a32e2c094ed81f49d",
        measurementId: "G-KYJWMNY63N"
    };
    firebase.initializeApp(firebaseConfig);
    export default {
        name: "App",
        components: {
            Navbar
        },
        data() {
            return {
                user: {
                    login: false,
                    name: "",
                    admin: false,
                    token: "",
                    v: false,
                },
                change: true,
                favorites: [],
                it: undefined,
                pwa: false,
                deferredPrompt: undefined,
                loading: true,
                error: false,
                errorIndex: parseInt(Math.random() * 4)
            };
        },
        methods: {
            logout() {
                firebase.auth().signOut().then(() => {
                    location.reload();
                }).catch((error) => {
                });
            },
            reload() {
                console.log("reload");
                this.loading = true;
                window.scrollTo(0, 0);
                this.loadData();
            },
            async onSignIn(token, image) {
                this.user.login = true;
                this.user.image = image;
                this.user.logout = this.logout;
                let res = await fetch("./api/firebase-login.php" + ((this.it) ? "?invite=" + this.it : ""), {
                    method: "POST",
                    body: JSON.stringify({
                        token: token
                    }),
                    headers: {
                        "Content-Type": "application/json"
                    },
                    mode: "no-cors"
                });
                let data = await res.json();
                if (data.success) {
                    this.user.name = data.name;
                    this.user.token = data.token;
                    window.token = data.token;
                    this.user.admin = data.admin;
                    this.user.v = data.v;
                    try {
                        this.fcm();
                    } catch (error) {

                    }
                    this.loadFavorite();
                    setInterval(this.reToken, 3500 * 1000);
                    localStorage.removeItem("invite");
                    if (this.it) {
                        this.$router.push("/");
                    }
                } else {
                    // location.reload();
                }
            },
            leaveRouter() {
                this.loading = true;
                document.body.style.overflowY = "hidden";
                window.scrollTo(0, 0)
            },
            loadedRouter() {
                this.loading = false;
                document.body.style.overflowY = "auto";
            },
            invite(token) {
                this.it = token;
                localStorage.setItem("invite", this.it);
            },
            async loadFavorite() {
                let res = await fetch("./api/favorite.php", {
                    headers: {
                        "X-Token": this.user.token
                    }
                })
                let fs = await res.json();
                this.favorites = fs;
                return fs;
            },
            reToken() {
                if (this.user.login) {
                    fetch("./api/retoken.php", {
                        headers: {
                            "X-Token": this.user.token
                        }
                    }).then((res) => {
                        return res.json();
                    }).then((data) => {
                        if (data.success) {
                            this.user.token = data.token;
                            this.user.admin = data.admin;
                            this.user.v = data.v;
                            window.token = data.token;
                        } else {
                            location.reload();
                        }
                    });
                }
            },
            clicklogin() {
                firebase.auth().languageCode = 'zh-tw';
                var provider = new firebase.auth.GoogleAuthProvider();
                provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
                provider.addScope("https://www.googleapis.com/auth/userinfo.email");
                firebase.auth()
                    .signInWithPopup(provider)
                    .then((result) => {
                        console.log(result);
                    }).catch((error) => {
                        console.log(error);
                    });
            }
        },
        mounted() {
            window.loadFavorite = this.loadFavorite;
            this.pwa = (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');

            window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault();
                this.deferredPrompt = e;
            });

            var it = new URL(location.href).searchParams.get("invite");
            if (localStorage.getItem("invite")) {
                this.it = localStorage.getItem("invite");
                if (this.it == it) {
                    try {
                        this.$router.push("/");
                    } catch (error) {

                    }
                }
            }
            if (it) {
                this.invite(it);
            }

            firebase.auth().onIdTokenChanged(user => {
                if (user) {
                    user.getIdToken().then((token) => {
                        this.onSignIn(token, user.photoURL);
                    });
                }
            });
        }
    };
</script>