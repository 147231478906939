<template>
    <div class="mt-8">
        <div class="grid grid-cols-1 md:grid-cols-2 mb-8 gap-8">
            <div style="text-align: center">
                <img :src="data.main_image ? data.main_image.image:''" class="image" alt="" />
            </div>
            <div>
                <h1 class="text-center text-4xl font-bold mb-6 flex items-center justify-center">
                    {{ data.title }}
                    <button class="love" @click.stop.prevent="changeFavorite">
                        <span class="material-icons" v-if="favorite">
                            favorite
                        </span>
                        <span class="material-icons text-black" v-else>
                            favorite_border
                        </span>
                    </button>
                </h1>
                <table class="table-auto">
                    <tbody>
                        <tr>
                            <td>
                                <h4>首次更新</h4>
                            </td>
                            <td>
                                <h4>
                                    {{ first_updated.toLocaleString() }}
                                </h4>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4>最後更新</h4>
                            </td>
                            <td>
                                <h4>{{ last_updated.toLocaleString() }}</h4>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4>總季數</h4>
                            </td>
                            <td>
                                <h4>{{ data.seasons.length }}季</h4>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4>總集數</h4>
                            </td>
                            <td>
                                <h4>{{ episdoe_count }}集</h4>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4>影片總長度　</h4>
                            </td>
                            <td>
                                <h4>{{ duration }}</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="pt-2">
                    <div class="tags" v-for="t in data.tags" :key="t.id">
                        {{ t.title }}
                    </div>
                </div>
                <p class="pt-2">{{ data.info }}</p>
                <div class="pt-5">
                    <span class="btn" @click="share()">
                        分享
                    </span>
                    <a class="btn" target="_blank" :href="data.url" v-if="data.url != ''">官方網站</a>
                </div>
            </div>
        </div>
        <hr>
        <div v-if="user.v">
            <div v-for="(s, si) in data.seasons" class="my-3 bg-gray-200/50 rounded-md py-8 px-4" :key="si">
                <h3 class="font-bold text-xl">{{s.title}}</h3>
                <div class="flex flex-wrap gap-4 mt-3">
                    <router-link :to="'/watch?id='+e.id"
                        class="relative overflow-hidden bg-sky-600 px-6 py-3 text-white rounded-md shadow"
                        v-for="(e, ei) in s.episodes" :class="{ 'opacity-50': watched(e.id) }" :key="ei">
                        {{ e.title }}
                        <div class="absolute bg-purple-900 h-1 bottom-0 w-full left-0" v-if="watchlist[0] == e.id"></div>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="video-info" v-show="windows.video" @click.self="windows.video = false">
            <div>
                <div style="text-align: start">
                    <span class="material-icons close-icon" @click="windows.video = false">
                        close
                    </span>
                </div>
                <h3>{{ video.title }}</h3>
                <p>總長度：{{ video.duration }}</p>
                <p v-if="user.v">檔案大小：{{ video.size }}</p>
                <div v-if="video.size != '未下載' && user.v">
                    <p>解析度：{{ video.q }}</p>
                    <div class="btn wbtn" @click="play('d')">正常播放</div>
                    <div class="btn wbtn" @click="play('b')">
                        瀏覽器播放
                    </div>
                    <div class="btn wbtn" @click="play('dow')">
                        下載檔案
                    </div>
                </div>
                <div v-else>
                    <div class="btn wbtn" v-if="user.login">無法觀看</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .tags {
        margin: 5px;
        padding: 6px;
        background-color: #333;
        color: #fff;
        user-select: none;
        border-radius: 4px;
        display: inline-block;
    }

    .btn {
        padding: 4px 10px;
        border: 2px solid #333;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;
        transition: all 0.2s;
        display: inline-flex;
        align-items: center;
        margin-right: 5px;
        color: #000;
        text-decoration: none;
    }

    .btn:hover {
        background-color: #333;
        color: #f5f5f5;
    }

    .love {
        color: rgba(255, 75, 75, 0.9);
        user-select: none;
        cursor: pointer;
        font-size: 0;
        height: fit-content;
        display: inline-flex;
        align-items: center;
        margin-left: 8px;
    }

    .love>span {
        font-size: 32px;
    }

    .image {
        width: 100%;
        max-height: 750px;
        object-fit: contain;
        object-position: 0 0;
        user-select: none;
        pointer-events: none;
    }

    h4 {
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }

    .info-img {
        max-width: 300px;
    }

    .alert {
        position: absolute;
        left: 21px;
        background-color: #43b581;
        color: #fff;
        transition: all 0.3s;
        display: none;
        border-radius: 4px;
        user-select: none;
        transform: translateY(-42px);
        width: fit-content;
    }

    .alert::before {
        content: "";
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #43b581;
        transform: rotate(45deg);
        bottom: -5px;
        left: 35px;
    }
</style>

<script>
    export default {
        name: 'Anime',
        props: {
            user: Object,
            animes: Array,
            favorites: Array
        },
        data() {
            return {
                title: "",
                data: {
                    title: "",
                    main_image: {
                        image: ""
                    },
                    seasons: [],
                    description: "",
                    tags: []
                },
                first_updated: new Date(),
                last_updated: new Date(0),
                duration: "00:00:00",
                episdoe_count: 0,
                info: "",
                video: {
                    id: 0,
                    title: "",
                    duration: "",
                    size: "",
                    url: "",
                    q: ""
                },
                windows: {
                    video: false,
                    alert: false
                },
                alert: "",
                favorite: false,
                watchlist: []
            };
        },
        methods: {
            load() {
                document.title = this.title + " - Anime";
                fetch("./api/animeinfo.php?title=" + this.title, {
                    headers: (this.user.login) ? {
                        "X-Token": this.user.token
                    } : {}
                }).then((res) => {
                    return res.json();
                }).then((data) => {
                    this.data = data;
                    this.data.main_image.image = this.data.main_image.image;
                    this.data.seasons.forEach((s) => {
                        if (this.first_updated > new Date(s.first_updated)) {
                            this.first_updated = new Date(s.first_updated);
                        }
                        this.episdoe_count += s.episodes.length;
                    });
                    this.last_updated = new Date(data.last_updated);
                    var duration = data.duration;
                    var h = ("00" + parseInt(duration / 3600)).slice(-2);
                    var m = ("00" + parseInt(duration % 3600 / 60)).slice(-2);
                    var s = ("00" + parseInt(duration % 60)).slice(-2);
                    this.duration = h + ":" + m + ":" + s;
                    this.data.description = this.data.description.replace(/info-img/g, "info-img col-12 col-lg-5");
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                    }, 16);
                    this.checkFavorite();
                    this.loadwatch();
                    this.$parent.loadedRouter();
                });
            },
            checkFavorite() {
                this.favorite = false;
                for (var f of this.favorites) {
                    if (f == this.data.id) {
                        this.favorite = true;
                        break;
                    }
                }
            },
            top() {
                window.scrollTo(0, 0);
            },
            share() {
                var i = document.createElement("input");
                i.type = "text";
                i.value = "https://" + location.host + "/anime/" + this.title;
                i.value = i.value.replace(/\s/g, "%20");
                document.body.append(i);
                i.select();
                i.setSelectionRange(0, i.value.length);
                console.log(i);
                document.execCommand("copy");
                document.body.removeChild(i);
                this.alert = "已複製!";
                this.windows.alert = true;
                setTimeout(() => {
                    this.windows.alert = false;
                }, 1000);
            },
            changeFavorite() {
                fetch("api/changeFavorite.php?id=" + this.data.id + "&val=" + (!this.favorite), {
                    method: "POST",
                    headers: {
                        "X-Token": this.user.token
                    }
                }).then((res) => {
                    this.$parent.loadFavorite();
                });
                this.favorite = !this.favorite;
            },
            loadwatch() {
                if (this.data.id && this.user.login && this.user.token != "" && this.user.v) {
                    fetch("api/watchlist.php?id=" + this.data.id, {
                        headers: {
                            "X-Token": this.user.token
                        }
                    }).then((res) => {
                        return res.json();
                    }).then((list) => {
                        this.watchlist = list;
                    });
                }
            },
            watched(id) {
                return this.watchlist.indexOf(parseInt(id)) != -1;
            }
        },
        watch: {
            favorites: {
                deep: true,
                handler() {
                    if (this.user.login && this.user.token != "") {
                        this.checkFavorite();
                    }
                }
            },
            user: {
                deep: true,
                handler() {
                    this.loadwatch();
                }
            }
        },
        mounted() {
            console.log(this.$route);
            this.title = this.$route.params.title;
            this.load();
        },
        beforeRouteEnter(to, from, next) {
            var title = to.params.title;
            next((vm) => {
                vm.title = title;
            });
        },
        beforeRouteUpdate(to) {
            var title = to.params.title;
            to.title = title;
            to.load();
        },
        beforeRouteLeave(to, from, next) {
            this.$parent.leaveRouter();
            next();
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>