<template>
    <div class="gap-4">
        <div>
            <div class="">
                <Player v-show="src" :src="src" :poster="img" :next="next" :last_time="last_time" ref="player"></Player>
            </div>
        </div>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 pb-6 container">
            <h2 class="my-6 text-2xl font-bold">
                <router-link class="nav-link" :to="'/anime/' + anime_title">
                    {{ anime_title }}
                </router-link>
                {{ season_title }} [{{ title }}]
            </h2>
            <div class="mb-5">
                <div v-for="(s, si) in seasons" class="mb-3 bg-gray-200/50 rounded-md py-8 px-4" :key="si">
                    <h3 class="font-bold text-xl">{{s.title}}</h3>
                    <div class="flex flex-wrap gap-4 mt-3">
                        <router-link :to="'/watch?id='+e.id"
                            class="relative overflow-hidden bg-sky-600 px-6 py-3 text-white rounded-md shadow"
                            v-for="(e, ei) in s.episodes" :class="{ 'opacity-50': e.watch == '1' }" :key="ei">
                            {{ e.title }}
                            <div class="absolute bg-purple-900 h-1 bottom-0 w-full left-0" v-if="id == e.id">
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Player from '@/components/Player';
    export default {
        name: 'Watch',
        props: {
            user: Object,
            favorites: Array
        },
        components: { Player },
        data() {
            return {
                id: 0,
                title: "",
                anime_title: "",
                season_title: "",
                volume: 1,
                img: "",
                src: undefined,
                next: undefined,
                player: undefined,
                episode_id: -1,
                image: {
                    src: "",
                    width: 0,
                    height: 0
                },
                seasons: [],
                season: {},
                last_time: -1
            };
        },
        methods: {
            load() {
                if (!this.user.login || !this.user.v) {
                    return;
                }
                if (this.id == this.episode_id) {
                    return;
                }
                fetch("api/videodata.php?id=" + this.id + (localStorage.getItem("local") ? "&local=true" : ""), {
                    headers: {
                        "X-Token": this.user.token
                    }
                }).then((res) => {
                    return res.json();
                }).then((data) => {
                    window.scrollTo(0, 0);
                    if (data.success) {
                        this.src = data.src;
                        this.episode_id = data.id;
                        this.episodes = data.season;
                        this.title = data.title;
                        this.anime_title = data.anime_title;
                        this.season_title = data.season_title;
                        this.img = data.img;
                        this.image.src = data.image;
                        this.image.width = data.image_width;
                        this.image.height = data.image_height;
                        this.season_id = data.season_id;
                        this.seasons = data.seasons;
                        this.last_time = data.last_time;
                        this.season = this.seasons.filter((s) => {
                            s.show = false;
                            return s.id == this.season_id;
                        })[0];
                        this.season.show = true;
                        this.next = undefined;
                        for (var i = 0; i <= this.season.episodes.length - 1; i++) {
                            if (this.season.episodes[i].id == this.id && this.season.episodes[i + 1]) {
                                this.next = this.season.episodes[i + 1];
                            }
                        }
                        document.title = this.anime_title + " " + this.season_title + " [" + this.title + "] - Anime";
                        this.playVideo();
                        this.$refs.player.onsave(this.save);
                    } else {
                        alert(data.message);
                        this.$router.back();
                    }
                    this.$parent.loadedRouter();
                });
            },
            playVideo() {
                if (!navigator.mediaSession) {
                    return;
                }
                navigator.mediaSession.metadata = new MediaMetadata({
                    title: this.anime_title + " " + this.season_title + " [" + this.title + "]",
                    artwork: [
                        {
                            src: this.image.src,
                            sizes: this.image.width + "x" + this.image.height
                        }
                    ]
                });
                navigator.mediaSession.setActionHandler('play', () => {
                    this.$refs.player.play();
                });

                navigator.mediaSession.setActionHandler('pause', () => {
                    this.$refs.player.pause();
                });
                if (this.next) {
                    navigator.mediaSession.setActionHandler('nexttrack', () => {
                        this.$refs.player.nextEpisode();
                    });
                }
            },
            play(e) {
                if (e.id != this.id) {
                    this.$refs.player.nextEpisode(e);
                }
            },
            save(time) {
                fetch("api/watch.php?episode_id=" + this.id + "&time=" + time, {
                    headers: {
                        "X-Token": this.user.token
                    }
                });
            }
        },
        watch: {
            user: {
                handler() {
                    this.load();
                },
                deep: true
            }
        },
        beforeRouteEnter(to, from, next) {
            var id = to.query.id;
            next((vm) => {
                vm.id = id;
                vm.load();
            });
        },
        beforeRouteUpdate(to, from, next) {
            var id = to.query.id;
            this.$parent.leaveRouter();
            this.id = id;
            this.load();
            next();
        },
        beforeRouteLeave(to, from, next) {
            this.$parent.leaveRouter();
            next();
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .flex-3 {
        flex: 3;
    }
</style>