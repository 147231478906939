<template>
    <div>
        <AnimeListBlock @open="setOpenData" id="myupdated" v-if="myupdated.length != 0" :animes="myupdated"
            title="近期更新(我的動畫)" row="3" :openData="openData" :reset="reset">
        </AnimeListBlock>
        <AnimeListBlock @open="setOpenData" id="recent" v-if="recent.length != 0" :animes="recent" title="最近觀看的動畫"
            row="2" :openData="openData" :reset="reset">
        </AnimeListBlock>
        <AnimeListBlock @open="setOpenData" id="animes" v-if="animes.length != 0" :animes="animes" title="近期更新" row="2"
            :openData="openData" :reset="reset">
        </AnimeListBlock>
        <AnimeListBlock @open="setOpenData" id="news" v-if="news.length != 0" :animes="news" title="新上架" row="1"
            :openData="openData" :reset="reset">
        </AnimeListBlock>
        <AnimeListBlock @open="setOpenData" id="randomAnimes" v-if="randomAnimes.length != 0" :animes="randomAnimes"
            title="隨機推薦" row="3" :openData="openData" :reload="true" @reload="random()" :reset="reset"></AnimeListBlock>
    </div>
</template>

<script>
    import AnimeListBlock from "@/components/Anime/AnimeListBlock";
    export default {
        name: 'Index',
        components: {
            AnimeListBlock
        },
        props: {
            user: Object,
            favorites: Array
        },
        data() {
            return {
                animes: [],
                myupdated: [],
                news: [],
                update: [],
                season: [],
                randomAnimes: [],
                last_season: [],
                recent: [],
                openData: JSON.parse(localStorage.getItem("index_opendata") ?? "{}"),
                reset: 0
            }
        },
        methods: {
            async load() {
                document.title = "Anime";
                this.reset++
                try {
                    await this.loadAnime();
                } catch (error) {
                    this.$parent.error = true;
                }
                this.group();
                this.loadRecent();
            },
            setOpenData(id, value) {
                this.openData[id] = value;
            },
            async loadRecent() {
                if (this.animes.length == 0) {
                    return;
                }
                if (!this.user.login) {
                    return;
                }
                let res = await fetch("./api/recent.php", {
                    headers: {
                        "X-Token": this.user.token
                    }
                })
                let recent_id = await res.json();
                this.recent = [];
                for (let id of recent_id) {
                    let anime = this.animes.find((a) => {
                        return a.id == id;
                    });
                    if (anime) {
                        this.recent.push(anime);
                    }
                }
                this.group();
                this.$parent.loadedRouter();
                setTimeout(() => {
                    if (!localStorage.getItem("index_scroll")) {
                        return
                    }
                    let y = parseFloat(localStorage.getItem("index_scroll"));
                    window.scrollTo(0, y);
                }, 16);
            },
            group() {
                let animes = this.animes.slice();
                let myupdated = animes.filter((a) => {
                    return a.favorite
                });

                myupdated.sort((a, b) => {
                    return new Date(b.last_updated).getTime() - new Date(a.last_updated).getTime()
                });
                this.myupdated = myupdated;

                animes.sort((a, b) => {
                    return parseInt(b.id) - parseInt(a.id)
                });
                this.news = animes.slice(0, 48);

                if (this.randomAnimes.length == 0) {
                    this.random();
                }
            },
            random() {
                this.randomAnimes = [];
                if (localStorage.getItem("index_random")) {
                    let ids = JSON.parse(localStorage.getItem("index_random"));
                    for (let id of ids) {
                        let anime = this.animes.find((a) => {
                            return a.id == id;
                        });
                        if (anime) {
                            this.randomAnimes.push(anime);
                        }
                    }
                    localStorage.removeItem("index_random");
                    return
                }

                let notFavorites = this.animes.filter((a) => {
                    return !a.favorite
                });

                let randomCount = Math.min(notFavorites.length, 6);
                shuffle(notFavorites);
                this.randomAnimes = notFavorites.slice(0, randomCount);
            },
            async loadAnime() {
                let res = await fetch("api/all.php", {
                    method: "GET"
                });
                let data = await res.json();
                data.forEach((a) => {
                    a.favorite = this.favorites.indexOf(a.id) != -1;
                });
                this.animes = data;
            },
        },
        watch: {
            animes: {
                handler() {
                    this.group();
                },
                deep: true
            },
            favorites: {
                handler() {
                    this.loadRecent();
                    this.animes.forEach((a) => {
                        a.favorite = this.favorites.indexOf(a.id) != -1;
                    });
                },
                deep: true
            }
        },
        mounted() {
            window.reloadIndex = this.load;
            this.load();
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
            });
        },
        beforeRouteLeave(to, from, next) {
            localStorage.setItem("index_scroll", window.scrollY);
            localStorage.setItem("index_opendata", JSON.stringify(this.openData));
            localStorage.setItem("index_random", JSON.stringify(this.randomAnimes.map((a) => {
                return a.id;
            })));

            this.$parent.leaveRouter();
            next();
        }
    }

    function shuffle(array) {
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>