<template>
    <router-link :to="'/anime/'+anime.title" class="anime shadow-md" @mouseenter="mouseenter()"
        @mouseleave="mouseleave()">
        <div class="anime-img">
            <img :src="anime.image" alt="" />
        </div>
        <div class="anime-title">
            {{ anime.title }}
        </div>
        <button class="love" @click.stop.prevent="changeFavorite">
            <span class="material-icons" v-if="anime.favorite">
                favorite
            </span>
            <span class="material-icons text-white" v-else>
                favorite_border
            </span>
        </button>
    </router-link>
</template>

<script>
    export default {
        name: 'AnimeBlock',
        props: {
            anime: Object,
            focusID: Number
        },
        data() {
            return {
                error: false
            }
        },
        methods: {
            mouseenter() {
                this.$emit("focus", this.anime.id);
            },
            mouseleave() {
                this.$emit("focus", 0);
            },
            changeFavorite() {
                fetch("api/changeFavorite.php?id=" + this.anime.id + "&val=" + (!this.anime.favorite), {
                    method: "POST",
                    headers: {
                        "X-Token": window.token
                    }
                }).then((res) => {
                    window.loadFavorite();
                });
                this.anime.favorite = !this.anime.favorite;
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .anime {
        user-select: none;
        background-color: #fff;
        border-radius: 6px;
        overflow: hidden;
        text-align: center;
        transition: all 0.25s;
        position: relative;
    }

    .anime:hover {
        background-color: #e9e9e9;
    }

    .anime-img {
        width: 100%;
        aspect-ratio: 230 / 320;
    }

    .anime-img>img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
    }

    .anime-title {
        margin: 4px 8px;
        font-weight: bold;
        font-size: 18px;
        overflow: hidden;
        white-space: nowrap;
    }

    .love {
        color: rgba(255, 75, 75, 0.9);
        user-select: none;
        cursor: pointer;
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 0;
    }

    .love>.text-white {
        opacity: 0.7;
    }
</style>