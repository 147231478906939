<template>
    <div class="my-10">
        <h1 class="text-2xl mb-4 font-bold flex items-center select-none">
            搜尋
        </h1>
        <input type="text" v-model="keyword" class="w-full py-3 px-4 rounded shadow outline-none" placeholder="關鍵字" />
        <div class="box mt-5">
            <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 mb-10">
                <AnimeItem v-for="a in result" :key="a.id" :anime="a" :focusID="0" :score="a.score">
                </AnimeItem>
            </div>
        </div>
    </div>
</template>

<script>
    import AnimeItem from "@/components/Anime/AnimeItem";
    export default {
        name: 'Search',
        components: {
            AnimeItem
        },
        props: {
            user: Object,
            favorites: Array
        },
        data() {
            return {
                keyword: "",
                animes: [],
                result: []
            };
        },
        methods: {
            load() {
                fetch("api/search.php").then((res) => {
                    return res.json();
                }).then((data) => {
                    data.forEach((a) => {
                        a.favorite = this.favorites.indexOf(a.id) != -1;
                    });
                    this.animes = data;
                    this.result = data.slice();
                    this.$parent.loadedRouter();
                });
            },
            search() {
                if (this.keyword == "") {
                    this.result = this.animes.slice();
                    return;
                }
                let chars = this.keyword.toLowerCase().split("");
                this.result = [];
                let animes = this.animes.slice();

                for (let anime of animes) {
                    let titleScore = this.comp(chars, anime.title.toLowerCase()) * 10;
                    let infoScore = this.comp(chars, anime.text.toLowerCase());
                    titleScore += anime.title.toLowerCase().indexOf(this.keyword.toLowerCase()) != -1 ? 15 : 0;
                    infoScore += anime.text.toLowerCase().indexOf(this.keyword.toLowerCase()) != -1 ? 10 : 0;
                    anime.score = titleScore + infoScore;
                }

                animes = animes.filter((a) => {
                    return a.score > 0;
                })

                animes.sort((a, b) => {
                    return b.score - a.score;
                });
                console.log(animes);

                this.result = animes;
            },
            comp(chars, b) {
                let score = 0;
                for (let c of chars) {
                    if (b.indexOf(c) != -1) {
                        score++;
                    }
                }
                return score;
            }
        },
        watch: {
            keyword() {
                this.keyword = this.keyword.replace(/\s{2,}/, " ");
                this.search();
            },
            favorites() {
                this.animes.forEach((a) => {
                    a.favorite = this.favorites.indexOf(a.id) != -1;
                });
            }
        },
        mounted() {
            this.load();
        },
        beforeRouteEnter(to, from, next) {
            document.title = "搜尋 - Anime";
            next();
        },
        beforeRouteLeave(to, from, next) {
            this.$parent.leaveRouter();
            next();
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .box {
        position: relative;
        overflow: hidden;
    }
</style>