<template>
    <div>
        <AnimeListBlock id="favorite" :animes="listFavorites()" title="我的動畫" row="1000" :openData="{}">
        </AnimeListBlock>
    </div>
</template>

<script>
    import AnimeListBlock from "@/components/Anime/AnimeListBlock";
    export default {
        name: 'Favorite',
        props: {
            user: Object,
            favorites: Array
        },
        components: {
            AnimeListBlock
        },
        data() {
            return {
                animes: []
            }
        },
        methods: {
            load() {
                document.title = "Anime";
                fetch("api/all.php", {
                    method: "GET"
                }).then((res) => {
                    return res.json();
                }).then((data) => {
                    data.forEach((a) => {
                        a.favorite = this.favorites.indexOf(a.id) != -1;
                    });
                    this.animes = data;
                    if (localStorage.getItem("scrollY_f")) {
                        setTimeout(() => {
                            var y = parseInt(localStorage.getItem("scrollY_f"));
                            window.scrollTo(0, y);
                        }, 20);
                    }
                    this.$parent.loadedRouter();
                });
            },
            changeFavorite(a) {
                console.log(this.user);
                fetch("api/changeFavorite.php?id=" + a.id + "&val=" + (!a.favorite), {
                    method: "POST",
                    headers: {
                        "X-Token": this.user.token
                    }
                }).then((res) => {
                    this.$parent.loadFavorite();
                });
            },
            checkFavorite() {
                this.animes.forEach((a) => {
                    a.favorite = this.favorites.indexOf(a.id) != -1;
                });
            },
            listFavorites() {
                let animes2 = [];
                for (let aid of this.favorites) {
                    let anime = this.animes.find((a) => {
                        return a.id == aid
                    });

                    if (anime) {
                        animes2.push(anime);
                    }
                }
                return animes2
            }
        },
        watch: {
            favorites: {
                deep: true,
                handler() {
                    if (this.user.login && this.user.token != "") {
                        this.checkFavorite();
                    }
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            if (!window.first && performance.navigation.type == performance.navigation.TYPE_RELOAD) {
                localStorage.setItem("scrollY_f", "0");
                window.first = true;
            }
            next((vm) => {
                vm.load();
            });
        },
        beforeRouteLeave(to, from, next) {
            localStorage.setItem("scrollY_f", window.scrollY);
            this.$parent.leaveRouter();
            next();
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>