<template>
    <nav class="bg-slate-900 select-none sticky top-0 z-50 shadow-md">
        <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div class="relative flex items-center justify-between h-16">
                <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    <!-- Mobile menu button-->
                    <button type="button" @click="openMenu = !openMenu"
                        class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        aria-controls="mobile-menu" aria-expanded="false">
                        <span class="sr-only">Open main menu</span>
                        <svg class="h-6 w-6" v-if="!openMenu" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                        <svg class="h-6 w-6" v-if="openMenu" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                    <a href="/" @click.stop.prevent="toIndex()"
                        class="flex-shrink-0 flex items-center text-white font-black text-2xl">
                        Anime
                    </a>
                    <div class="hidden sm:block sm:ml-6">
                        <div class="flex space-x-4">
                            <a href="/" @click.stop.prevent="toIndex()"
                                class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                aria-current="page">首頁</a>
                            <router-link to="/favorite" v-if="user.v"
                                class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                我的動畫</router-link>
                            <router-link to="/history" v-if="user.v"
                                class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                觀看紀錄</router-link>
                            <router-link to="/search" v-if="user.v"
                                class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                                搜尋</router-link>
                        </div>
                    </div>
                </div>
                <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <!-- Profile dropdown -->
                    <div class="ml-3 relative" v-if="user.login">
                        <div>
                            <button type="button" @click.stop="openProfile=!openProfile"
                                class="outline-none bg-gray-800 flex text-sm rounded-full active:outline-none"
                                aria-expanded="false" aria-haspopup="true">
                                <img class="h-8 w-8 rounded-full" :src="user.image" alt="">
                            </button>
                        </div>
                        <div v-show="openProfile"
                            class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                            <span @click.stop="install()" v-if="deferredPrompt"
                                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                role="menuitem" tabindex="-1" id="user-menu-item-0">安裝</span>
                            <span @click.stop="user.logout()"
                                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                role="menuitem" tabindex="-1" id="user-menu-item-0">登出</span>
                        </div>
                    </div>
                    <div class="ml-3 relative" v-else>
                        <div>
                            <button type="button" @click="login"
                                class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                                aria-expanded="false" aria-haspopup="true">
                                登入
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="sm:hidden" v-show="openMenu">
            <div class="px-2 pt-2 pb-3 space-y-1">
                <a href="/" @click.stop.prevent="toIndex()"
                    class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                    首頁
                </a>
                <router-link to="/favorite"
                    class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                    我的動畫</router-link>
                <router-link to="/history"
                    class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                    觀看紀錄</router-link>
                <router-link to="/search"
                    class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                    搜尋</router-link>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        props: {
            user: Object,
            deferredPrompt: Object
        },
        data() {
            return {
                openMenu: false,
                openProfile: false
            };
        },
        methods: {
            login() {
                this.$emit("clicklogin");
            },
            install() {
                if (this.deferredPrompt) {
                    this.deferredPrompt.prompt();
                    this.deferredPrompt.userChoice.then((choiceResult) => {
                        if (choiceResult.outcome === 'accepted') {
                            this.pwa = true;
                        }
                        this.deferredPrompt = null;
                    });
                }
            },
            toIndex() {
                localStorage.removeItem("index_scroll");
                localStorage.removeItem("index_opendata");
                localStorage.removeItem("index_random");
                window.scrollTo(0, 0);
                if (this.$route.path !== "/") {
                    this.$router.push("/")
                } else {
                    this.$parent.leaveRouter();
                    window.reloadIndex();
                }
            }
        },
        mounted() {
            document.body.addEventListener("click", () => {
                if (this.openProfile) {
                    this.openProfile = false
                }
            })
        }
    }
</script>