import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index'
import Search from '@/views/Search'
import Watch from '@/views/Watch'
import Anime from '@/views/Anime'
import History from '@/views/History'
import Favorite from '@/views/Favorite'

Vue.use(VueRouter)

const routes = [
    {
        path: '/history',
        name: 'History',
        component: History
    },
    {
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/search',
        name: 'Search',
        component: Search
    },
    {
        path: '/favorite',
        name: 'Favorite',
        component: Favorite
    },
    {
        path: '/watch',
        name: 'Watch',
        component: Watch
    },
    {
        path: '/anime/:title',
        name: 'Anime',
        component: Anime
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default router
