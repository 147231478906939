<template>
    <div>
        <div class="video" ref="player" @click="toggle_play" :class="{ nomouse: playing && !control_show }">
            <video autoplay preload="preload" ref="video" :class="{ 'video-full': fullscreen }" crossorigin="anonymous"></video>
            <img :src="poster" class="poster" v-if="showPoster" alt="">
            <div class="video-bar" :class="{ 'video-bar-hide': !control_show }">
                <div class="video-bar-control" @click.stop>
                    <div class="video-timebox" @mousedown="settime($event)">
                        <div>
                            <div class="video-loadedtime" :style="{ width: loaded_width }" :class="{
                                    time_width_transition: time_width_transition,
                                }"></div>
                            <div class="video-time" :style="{ width: time_width }"></div>
                        </div>
                    </div>
                    <div class="video-controls">
                        <div class="video-controls-left">
                            <span class="material-icons video-bar-icon" @click="toggle_play()">
                                {{ playing ? "pause" : "play_arrow" }}
                            </span>
                            <span class="material-icons video-bar-icon" @click="nextEpisode"
                                :class="{ 'video-bar-disable': !next }">
                                skip_next
                            </span>
                            <span @mouseenter="venter" @mouseleave="vleave">
                                <span class="material-icons video-bar-icon">
                                    {{
                                    volume > 0.5
                                    ? "volume_up"
                                    : volume > 0
                                    ? "volume_down"
                                    : "volume_mute"
                                    }}
                                </span>
                                <div class="video-volume-box" :class="{
                                        'video-volume-show': volume_hover,
                                    }">
                                    <div class="video-volume" @mousedown="vdown" ref="volume">
                                        <div class="video-volume-bg" :style="{
                                                width: volume * 100 + '%',
                                            }"></div>
                                        <div class="video-volume-btn" :style="{
                                                left: volume * 100 + '%',
                                            }"></div>
                                    </div>
                                </div>
                            </span>
                            <span class="video-bar-timetext">
                                <span>{{ time_text }}</span>
                                /
                                <span>{{ duration_text }}</span>
                            </span>
                        </div>
                        <div class="video-controls-right">
                            <span class="material-icons video-bar-icon" @click="click_pip">
                                {{
                                pip
                                ? "picture_in_picture"
                                : "picture_in_picture_alt"
                                }}
                            </span>
                            <span class="material-icons video-bar-icon" @click="click_fullscreen">
                                {{
                                fullscreen
                                ? "fullscreen_exit"
                                : "fullscreen"
                                }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="video-touch" v-if="touch" @touchend="touchend"></div>
            <div class="video-play" v-show="!played">
                <div class="video-loading" v-if="loading">
                    <div class="video-loading-dot" style="animation-delay: -0s;"></div>
                    <div class="video-loading-dot" style="animation-delay: -0.5s;"></div>
                    <div class="video-loading-dot" style="animation-delay: -0.75s;"></div>
                    <div class="video-loading-dot" style="animation-delay: -1s;"></div>
                    <div class="video-loading-dot" style="animation-delay: -1.5s;"></div>
                </div>
                <svg viewBox="0 0 24 24" fill="white" width="88px" height="88px" class="playbtn" v-else>
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l6 4.5-6 4.5z" />
                </svg>
            </div>
            <button v-if="currentTime >= 0.95" v-show="control_show" :disabled="!next"
                class="next whitespace-nowrap mx-auto flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                @click="nextEpisode">{{next ? "下一集" : "沒有下一集了QAQ"}}</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Player',
        props: ["src", "poster", "next", "last_time"],
        data() {
            return {
                video: undefined,
                fullscreen: false,
                playing: false,
                pip: false,
                player: undefined,
                time_text: "00:00",
                duration_text: "00:00",
                time_width: "0%",
                currentTime: 0,
                loaded_width: "0%",
                played: false,
                timeout: -1,
                control_show: true,
                time_width_transition: true,
                cb: () => { },
                volume: 1,
                volume_hover: false,
                volume_down: false,
                touch: true,
                last_touch: -1,
                showPoster: true,
                loading: true,
                lastSaveTime: 0
            }
        },
        methods: {
            reset() {
                this.played = false;
                this.time_text = "00:00";
                this.playing = false;
                this.duration_text = "00:00";
                this.time_width = "0%";
                this.loaded_width = "0%";
                this.control_show = true;
                this.showPoster = true;
                this.currentTime = 0;
                this.loading = true;
                this.lastSaveTime = 0;
            },
            click_pip() {
                if (this.pip) {
                    document.exitPictureInPicture();
                } else {
                    this.video.requestPictureInPicture();
                }
            },
            click_fullscreen() {
                if (this.fullscreen) {
                    document.exitFullscreen();
                } else {
                    this.$refs.player.requestFullscreen({
                        navigationUI: "hide"
                    });
                }
            },
            loadeddata() {
                console.log("loadedmetadata");
                var duration = this.video.duration;
                this.time_text = "00:00";
                this.duration_text = this.formatTime(duration);
                this.loading = false;
            },
            timeupdate() {
                if (Date.now() - this.lastSaveTime >= 30 * 1000) {
                    this.lastSaveTime = Date.now();
                    this.save();
                }
                this.currentTime = this.video.currentTime / this.video.duration;
                this.time_text = this.formatTime(this.video.currentTime);
                this.time_width = (this.video.currentTime / this.video.duration * 100) + "%";
                this.playing = !this.video.paused;
                var max_buffer = -1;
                var buffers = this.video.buffered;
                for (var i = 0; i <= buffers.length - 1; i++) {
                    var t = buffers.end(i);
                    if (t > max_buffer) {
                        max_buffer = t;
                    }
                }
                this.loaded_width = (max_buffer / this.video.duration * 100) + "%";
            },
            toggle_play() {
                if (this.loading) {
                    return;
                }
                this.showPoster = false;
                if (this.video.paused) {
                    this.video.play();
                    this.resetTime();
                    if (!this.played && this.last_time != -1) {
                        this.video.currentTime = parseInt(this.last_time);
                    }
                    this.played = true;
                } else {
                    this.clearTime(true);
                    this.video.pause();
                }
                this.save();
                this.playing = !this.video.paused;
            },
            formatTime(t) {
                var h = parseInt(t / 3600);
                var m = parseInt(t % 3600 / 60);
                var s = parseInt(t % 60);
                h = ("00" + h).slice(-2);
                m = ("00" + m).slice(-2);
                s = ("00" + s).slice(-2);
                if (h != "00") {
                    return h + ":" + m + ":" + s;
                } else {
                    return m + ":" + s;
                }
            },
            settime(e) {
                var p = (e.pageX - 12) / (document.body.clientWidth - 24);
                if (p < 0) {
                    p = 0;
                } else if (p > 1) {
                    p = 1;
                }
                this.video.currentTime = this.video.duration * p;
                this.time_width_transition = false;
                this.time_width = (p * 100) + "%";
                this.time_width_transition = true;
                this.save();
            },
            resetTime() {
                this.control_show = true;
                window.clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.control_show = false;
                }, 3200);
            },
            clearTime(a) {
                this.control_show = a;
                window.clearTimeout(this.timeout);
            },
            keydown(e) {
                if (e.key == "ArrowRight" && this.played) {
                    this.video.currentTime += 10;
                    e.preventDefault();
                } else if (e.key == "ArrowLeft" && this.played) {
                    this.video.currentTime -= 10;
                    e.preventDefault();
                } else if (e.key == " ") {
                    this.toggle_play();
                    e.preventDefault();
                } else if (e.key == "F" || e.key == "f") {
                    this.click_fullscreen();
                }
            },
            pause() {
                if (!this.video.paused) {
                    this.video.pause();
                    this.resetTime();
                }
            },
            nextEpisode(episode = undefined) {
                if (!episode || !episode.id || !episode.title) {
                    episode = this.next;
                }
                if (episode && episode.id) {
                    if (this.video.currentTime > 0) {
                        this.save();
                    }
                    this.pause();
                    this.$router.push("/watch?id=" + episode.id);
                }
            },
            onsave(cb) {
                this.cb = cb;
            },
            save() {
                if (this.video.currentTime <= 5) {
                    return;
                }
                this.cb(this.video.currentTime);
            },
            venter() {
                this.volume_hover = true;
            },
            vleave() {
                this.volume_hover = false;
                this.volume_down = false;
            },
            vdown(e) {
                this.volume_down = true;
                this.setvolume(e);
            },
            mouseup() {
                this.volume_down = false;
            },
            mousemove(e) {
                if (this.volume_down) {
                    this.setvolume(e);
                }
            },
            setvolume(e) {
                var vol = this.$refs.volume;
                var rect = vol.getBoundingClientRect();
                var vx = rect.left + window.scrollX;
                var v = 0;
                if (e.pageX < vx) {
                    v = 0;
                } else if (e.pageX > vx + vol.clientWidth) {
                    v = 1;
                } else {
                    v = (e.pageX - vx) / vol.clientWidth;
                }
                this.volume = v;
                localStorage.setItem("volume", this.volume);
            },
            touchend(e) {
                if (e.changedTouches.length == 1) {
                    var now = e.timeStamp;
                    if (now - this.last_touch < 450) {
                        var x = e.changedTouches[0].clientX;
                        if (x / window.innerWidth < 0.5) {
                            this.video.currentTime -= 10;
                        } else {
                            this.video.currentTime += 10;
                        }
                    }
                }
                this.last_touch = e.timeStamp;
            }
        },
        watch: {
            src() {
                this.video.onloadeddata = this.loadeddata;
                this.video.onloadedmetadata = this.loadeddata;
                this.video.addEventListener("loadeddata",this.loadeddata.bind(this));
                this.video.addEventListener("loadedmetadata",this.loadeddata.bind(this));
                this.video.src = this.src;
                this.reset();
            },
            volume() {
                this.video.volume = this.volume / 2;
            },
            poster() {
                this.video.poster = this.poster;
            },
            fullscreen() {
                if (screen.orientation) {
                    if (this.fullscreen) {
                        screen.orientation.lock("landscape");
                    } else {
                        screen.orientation.unlock();
                    }
                } else if (screen.lockOrientation) {
                    if (this.fullscreen) {
                        screen.lockOrientation("landscape");
                    } else {
                        screen.unlockOrientation();
                    }
                }
            }
        },
        mounted() {
            this.video = this.$refs.video;
            this.video.autoplay = false;
            this.player = this.$refs.player;
            document.body.addEventListener("keydown", this.keydown);
            document.body.addEventListener("mouseup", this.mouseup);
            document.body.addEventListener("mousemove", this.mousemove);

            this.player.onmouseleave = () => {
                if (this.played && this.playing) {
                    this.control_show = false;
                }
            }

            this.player.onmousemove = () => {
                if (this.played && this.playing) {
                    this.resetTime();
                }
            }

            this.video.poster = this.poster;
            this.video.addEventListener('leavepictureinpicture', () => {
                this.pip = false;
            });

            this.video.addEventListener('enterpictureinpicture', () => {
                this.pip = true;
            });

            this.video.addEventListener('ended', () => {
                this.control_show = true;
                this.playing = false;
                this.save();
            });

            this.video.addEventListener('loadeddata', this.loadeddata);
            this.video.addEventListener('loadedmetadata', this.loadeddata);
            this.video.addEventListener('timeupdate', this.timeupdate);


            this.player.addEventListener('fullscreenchange', () => {
                if (document.fullscreenElement == this.player) {
                    this.fullscreen = true;
                } else {
                    this.fullscreen = false;
                }
            });
            this.video.src = this.src;
            if (localStorage.getItem("volume")) {
                this.volume = parseFloat(localStorage.getItem("volume"));
            }
            this.reset();
            if ("ontouchstart" in window) {
                this.touch = true;
            }
        },
        beforeDestroy() {
            document.body.removeEventListener("keydown", this.keydown);
            document.body.removeEventListener("mouseup", this.mouseup);
            document.body.removeEventListener("mousemove", this.mousemove);
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .next {
        position: absolute;
        top: 30px;
        right: 30px;
    }

    .video-loading {
        width: 100px;
        height: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }

    .video-loading-dot {
        position: absolute;
        background-color: rgba(255, 255, 255, 1);
        width: 20px;
        height: 20px;
        border-radius: 100%;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        transform: rotate(0deg) translateY(-30px);
        animation: video-loading 2s infinite;
    }

    @keyframes video-loading {

        0% {
            transform: rotate(0deg) translateY(-30px);
        }

        100% {
            transform: rotate(360deg) translateY(-30px);
        }
    }


    .video {
        width: 100%;
        max-height: 768px;
        background: #000;
        position: relative;
        user-select: none;
        font-size: 0;
    }

    .video>video {
        width: 100%;
        max-height: 768px;
    }

    .poster {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        background-color: #000;
    }

    .video-bar {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 200px;
        opacity: 1;
        transition: all 0.5s;
        background-image: linear-gradient(0deg,
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5) 40%,
                rgba(0, 0, 0, 0));
    }

    .video-bar-control {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 48px;
    }

    .video-timebox {
        position: absolute;
        left: 12px;
        right: 12px;
        top: -4px;
        height: 12px;
        cursor: pointer;
    }

    .video-timebox>div {
        background-color: rgba(255, 255, 255, 0.4);
        height: 3px;
        position: absolute;
        left: 0px;
        right: 0px;
        top: 5px;
        transition: all 0.1s linear;
    }

    .video-loadedtime {
        position: absolute;
        left: 0;
        top: 0;
        width: 60%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        transition: width 0.25s linear;
    }

    .video-time {
        position: absolute;
        left: 0;
        top: 0;
        width: 30%;
        background-color: red;
        height: 100%;
    }

    .time_width_transition {
        transition: width 0.25s linear;
    }

    .video-timebox:hover>div {
        height: 5px;
        transform: translateY(-1px);
    }

    .video-time::before {
        display: block;
        position: absolute;
        right: 0px;
        top: calc(50% - 6px);
        pointer-events: none;
        width: 12px;
        height: 12px;
        border-radius: 8px;
        background-color: red;
        /* content: ""; */
        transition: all 0.1s linear;
        transform: scale(0);
    }

    .video-timebox:hover .video-time::before {
        transform: scale(1);
    }

    .video-bar {
        color: #fff;
    }

    .video-controls {
        height: 40px;
    }

    .video-controls-left {
        float: left;
        line-height: 20px;
        padding-left: 12px;
    }

    .video-controls-right {
        float: right;
        line-height: 20px;
        padding-right: 12px;
    }

    .video-bar-icon {
        width: 40px;
        height: 40px;
        display: inline-block;
        opacity: 0.8;
        font-size: 30px;
        vertical-align: text-top;
        cursor: pointer;
        transition: all 0.25s;
    }

    .video-bar-icon:hover {
        opacity: 1;
    }

    .video-full {
        max-height: 100% !important;
        height: 100%;
    }

    .video-play {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
    }

    .video-play svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        pointer-events: all;
    }

    .video-bar-hide {
        opacity: 0;
        pointer-events: none;
    }

    .nomouse {
        cursor: none;
    }

    .video-bar-disable {
        opacity: 0.4 !important;
        cursor: auto;
    }

    .video-bar-timetext {
        opacity: 0.9;
        font-size: 13px;
        vertical-align: text-top;
        margin-top: 5px;
        display: inline-block;
        padding-left: 8px;
    }

    .video-volume-box {
        height: 40px;
        display: inline-block;
        margin: 0px 2px;
        vertical-align: text-top;
        padding-top: 5px;
        width: 0;
        transition: width 0.3s;
        overflow: hidden;
    }

    .video-volume-show {
        width: 58px;
    }

    .video-volume {
        width: 52px;
        height: 3px;
        background-color: rgba(255, 255, 255, 0.2);
        position: relative;
        cursor: pointer;
        display: inline-block;
    }

    .video-volume-btn {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 100%;
    }

    .video-volume-bg {
        background-color: #fff;
        height: 100%;
    }

    .video-touch {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 52px;
        overflow: hidden;
    }
</style>