<template>
    <div class="relative my-10">
        <h2 class="text-2xl mb-4 font-bold flex items-center select-none">
            <span @click="clickReload()" class="cursor-pointer">{{title}}</span>
            <span class="material-icons ml-2 cursor-pointer" @click="clickReload()" v-if="reload">
                autorenew
            </span>
        </h2>
        <div class="box" :class="{'box-transition':transition}" :style="{'max-height':boxHeight() +'px'}">
            <div ref="items" class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 mb-10">
                <AnimeItem v-for="a in animes.slice(0,openRow * rowItemCount + rowItemCount)" :key="a.id" :anime="a"
                    :focusID="focusID" @focus="focus">
                </AnimeItem>
            </div>
            <div class="more-shadow" v-if="showMore()"></div>
        </div>
        <div class="more" v-if="showMore()">
            <button @click="open()"
                class="whitespace-nowrap mx-auto flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">更多</button>
            <button @click="showAll()" v-if="openRow != row"
                class="whitespace-nowrap mx-auto border border-transparent text-sm underline mt-2 underline-offset-2">顯示全部</button>
        </div>
        <div class="more" v-if="!showMore() && getMaxRow() != 1">
            <button @click="hideAll()"
                class="whitespace-nowrap mx-auto border border-transparent text-sm underline mt-2 underline-offset-2">收起全部</button>
        </div>
    </div>
</template>

<script>
    import AnimeItem from "@/components/Anime/AnimeItem";
    export default {
        name: 'AnimeListBlock',
        components: {
            AnimeItem
        },
        props: {
            animes: Array,
            title: String,
            row: String,
            reload: Boolean,
            id: String,
            openData: Object,
            reset: Number
        },
        data() {
            return {
                error: false,
                focusID: 0,
                openRow: 1,
                rowItemCount: 2,
                rowHeight: 0,
                transition: false
            }
        },
        methods: {
            focus(id) {
                this.focusID = parseInt(id);
            },
            resize() {
                let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);

                let width = document.body.clientWidth;
                if (width >= 1024) {
                    this.rowItemCount = 6;
                } else if (width >= 768) {
                    this.rowItemCount = 4;
                } else if (width >= 640) {
                    this.rowItemCount = 3;
                } else {
                    this.rowItemCount = 2;
                }
            },
            boxHeight() {
                if (!this.$refs["items"]) {
                    return window.innerHeight;
                }
                let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
                let itemsWidth = this.$refs["items"].clientWidth;
                let itemWidth = (itemsWidth - rem * (this.rowItemCount - 1)) / this.rowItemCount;
                this.rowHeight = itemWidth / 230 * 320 + 27 + 8;
                return (this.openRow - 1) * rem + this.rowHeight * (this.openRow + 0.2)
            },
            showMore() {
                let maxRow = this.getMaxRow();
                return this.openRow < maxRow;
            },
            getMaxRow() {
                return Math.ceil(this.animes.length / this.rowItemCount);
            },
            clickReload() {
                if (this.reload) {
                    this.$emit("reload");
                }
                this.openRow = parseInt(this.row);
            },
            open() {
                this.transition = true;
                this.openRow += 4
                this.$emit("open", this.id, this.openRow);
            },
            showAll() {
                this.transition = true;
                this.openRow += 100;
                this.$emit("open", this.id, this.openRow);
            },
            hideAll() {
                this.transition = false;
                this.openRow = parseInt(this.row);
                this.$emit("open", this.id, this.openRow);
            }
        },
        watch: {
            reset() {
                this.openRow = parseInt(this.row);
            }
        },
        mounted() {
            if (this.openData[this.id]) {
                this.openRow = this.openData[this.id];
            } else {
                this.openRow = parseInt(this.row);
            }
            window.addEventListener("resize", this.resize)
            this.resize();
        },
        beforeUnmount() {
            window.removeEventListener("resize", this.resize)
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .box {
        position: relative;
        overflow: hidden;
    }

    .box-transition {
        transition: max-height 0.5s;
    }

    .more {
        padding-top: 8px;
        width: 100%;
        text-align: center;
        overflow-x: hidden;
        overflow-y: visible;
    }

    .more-shadow {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50px;
        background-image: linear-gradient(180deg, rgba(243, 244, 246, 0), rgba(243, 244, 246, 1) 90%, rgba(243, 244, 246, 1));
    }
</style>