import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueLazyload from 'vue-lazyload'
import './registerServiceWorker'

require('@/assets/style.css');

Vue.use(VueLazyload);
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')

var pageAccessedByReload = (
    (window.performance.navigation && window.performance.navigation.type === 1) ||
    window.performance
        .getEntriesByType('navigation')
        .map((nav) => nav.type)
        .includes('reload')
);

if (pageAccessedByReload) {
    localStorage.removeItem("index_scroll");
    localStorage.removeItem("index_opendata");
    localStorage.removeItem("index_random");
}