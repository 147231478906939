<template>
    <div class="my-10">
        <h1 class="text-2xl mb-4 font-bold flex items-center select-none">
            觀看紀錄
        </h1>
        <div class="history-date">
            <div class="history" v-for="(h, i) in historys" :key="i">
                <h3 class="history-title">{{ h.title }}</h3>
                <div class="grid grid-cols-1  md:grid-cols-2  xl:grid-cols-4 gap-4 my-4">
                    <div class="history-anime" v-for="(anime, j) in h.anime" :key="j">
                        <div class="history-card">
                            <div class="history-image">
                                <router-link :to="'/anime/' + anime.title">
                                    <img v-lazy="anime.image" alt="" />
                                </router-link>
                            </div>
                            <div class="history-info">
                                <h3 class="my-2 font-bold">{{ anime.title }}</h3>
                                <div class="history-episode" v-for="(e, k) in anime.episode" :key="k">
                                    <div class="history-episode-part">
                                        {{ e.title }}
                                    </div>
                                    <div class="history-episode-part" style="flex: 1">
                                        {{ e.time }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'History',
        props: {
            user: Object,
            favorites: Array
        },
        data() {
            return {
                historys: []
            };
        },
        methods: {
            load() {
                if (!this.user.login || !this.user.v) {
                    return;
                }
                fetch("api/history.php", {
                    headers: {
                        "X-Token": this.user.token
                    }
                }).then((res) => {
                    return res.json();
                }).then((data) => {
                    var last_day = -1;
                    var last_anime_id = -1;
                    var now = new Date();
                    now.setHours(0);
                    now.setMinutes(0);
                    now.setSeconds(0);
                    now.setMilliseconds(0);
                    for (var h of data) {
                        var time = new Date(parseInt(h.time) * 1000);
                        h.time = new Date(parseInt(h.time) * 1000);
                        time.setHours(0);
                        time.setMinutes(0);
                        time.setSeconds(0);
                        time.setMilliseconds(0);
                        var day = parseInt(Math.abs(now - time) / (24 * 60 * 60 * 1000));
                        var ho;
                        if (last_day == day) {
                            ho = this.historys[this.historys.length - 1];
                        } else {
                            var title;
                            if (day == 0) {
                                title = "今天";
                            } else if (day == 1) {
                                title = "昨天";
                            } else if (day <= 5) {
                                title = day + "天前";
                            } else if (day <= 100) {
                                title = (time.getMonth() + 1) + "月" + time.getDate() + "日";
                            } else {
                                title = time.getFullYear() + "/" + (time.getMonth() + 1) + "/" + time.getDate() + "/";
                            }
                            ho = {
                                title: title,
                                anime: []
                            };
                            this.historys.push(ho);
                            last_day = day;
                            last_anime_id = -1;
                        }
                        var anime = undefined;
                        for (var a of ho.anime) {
                            if (a.id == h.anime_id) {
                                anime = a;
                            }
                        }
                        if (!anime) {
                            anime = {
                                id: h.anime_id,
                                title: h.anime_title,
                                image: h.image,
                                episode: []
                            };
                            ho.anime.push(anime);
                        }
                        anime.episode.push({
                            id: h.episode_id,
                            title: h.season_title + " [" + h.episode_title + "]",
                            time: this.timediff(h.time)
                        });
                    }
                    this.$parent.loadedRouter();
                });
            },
            timediff(time) {
                var now = new Date();
                var sec = Math.abs(now - time) / 1000;
                if (sec < 60) {
                    return "剛剛";
                } else if (sec < 60 * 60) {
                    return parseInt(sec / 60) + "分鐘前";
                } else if (sec < 60 * 60 * 24) {
                    return parseInt(sec / 60 / 60) + "小時前";
                } else {
                    return this.numberFormat(time.getHours()) + ":" + this.numberFormat(time.getMinutes());
                }
            },
            numberFormat(n) {
                return ("0" + n).slice(-2);
            }
        },
        watch: {
            user: {
                handler() {
                    this.load();
                },
                deep: true
            }
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                document.title = "觀看紀錄";
                vm.load();
            });
        },
        beforeRouteLeave(to, from, next) {
            this.$parent.leaveRouter();
            next();
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .history-title {
        overflow: hidden;
    }

    .history-title::after {
        content: "";
        display: inline-block;
        height: 2px;
        width: 100%;
        background-color: #aaa;
    }

    .history-anime {
        margin-bottom: 16px;
    }

    .history-anime>div {
        height: 180px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0px 0px 6px #aaa;
    }

    .history-card {
        display: flex;
    }

    .history-image {
        width: 120px;
        min-width: 120px;
        height: 180px;
        padding: 4px;
        display: inline-block;
    }

    .history-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .history-info {
        width: calc(100% - 120px);
        text-align: start;
        padding-left: 10px;
        padding-right: 8px;
        display: inline-block;
        position: relative;
        overflow: auto;
    }

    .history-info::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    .history-info::-webkit-scrollbar-track {
        background: #fff;
    }

    .history-info::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 5px;
    }

    .history-episode {
        padding-bottom: 2px;
        display: flex;
    }

    .history-episode-part {
        padding-left: 4px;
        text-align: start;
        flex: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>